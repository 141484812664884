<template>
  <div>
    <div class="personal-edit-container position-relative">
      <div
        class="header pl-3 pr-2 d-flex justify-content-between align-items-center"
      >
        <h3>
          {{ $t('編輯頭像') }}
        </h3>
        <i
          class="icon-X text-28 cursor-pointer text-primary"
          @click="$emit('close')"
        ></i>
      </div>
      <div class="avatars-wrapper bg-white w-100 scroll-bar">
        <b-container class="w-100 h-100">
          <b-row class="m-0 h-100">
            <b-col
              v-for="avatar in avatars"
              :key="avatar"
              class="d-flex justify-content-center"
            >
              <div
                class="avatar-box bg-white d-flex justify-content-center position-relative"
                @click="selectedAvatar = avatar"
              >
                <img
                  class="cursor-pointer rounded-circle"
                  :srcset="
                    require(`@/assets/img/personal/avatar/${avatar}.png?srcset`)
                  "
                />
                <i
                  v-if="
                    selectedAvatar
                      ? selectedAvatar === avatar
                      : userData.avatar === avatar
                  "
                  class="icon-Check position-absolute d-flex justify-content-center align-items-center"
                ></i>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div
        class="footer w-100 d-flex justify-content-center align-items-center"
      >
        <b-button size="sm" variant="primary" @click="submitUpdate">
          {{ $t('保存更改') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import userService from '@/services/user';
import {padStart} from 'lodash';
import {avatarsNumber} from '@/constant/env.js';

export default {
  data() {
    return {
      selectedAvatar: '',
      avatars: [],
      avatarsNumber,
      backendError: '',
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
  },
  mounted() {
    for (let i = 0; i < this.avatarsNumber; i++) {
      this.avatars.push(padStart(i, 4, '0'));
    }
  },
  methods: {
    async submitUpdate() {
      if (this.isLoading) return;
      this.$store.commit('env/setIsLoading', true);
      const apiData = {
        avatar: this.selectedAvatar || this.userData.avatar,
      };
      try {
        await userService.updateUser(apiData);
        await this.$store.dispatch('user/loginWithJwt');
        this.$Message.success(this.$t('個人資訊更改成功'));
        this.$emit('close');
      } catch (error) {
        this.$Message.error(this.$t('個人資訊更改失敗'));
      } finally {
        this.$store.commit('env/setIsLoading', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-edit-container {
  width: 544px;
  background: white;
  border-radius: $rounded-md;
  overflow: hidden;
  .header {
    height: 60px;
    color: $font-grayscale-1;
    border-bottom: 1px solid #ced4da;
  }
  .footer {
    bottom: 0;
    border-top: 1px solid rgba(199, 199, 199, 0.3);
    height: 56px;
    background: white;
  }
  .btn-primary {
    font-size: 16px;
    line-height: 28px;
  }
  .row {
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(5, 1fr);
  }
  .avatars-wrapper {
    .container {
      padding: 24px;
      .row {
        .col {
          padding: 0;
          .avatar-box {
            width: 80px;
            img {
              width: 80px;
              height: 80px;
            }
            i {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background-color: $positive;
              color: #ffffff;
              font-size: 24px;
              bottom: 0;
              right: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
